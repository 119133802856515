$base-color: #e3e3e3;
$accent-color: #fff;
$view-offset: -15vw;

//

.loading {
  position: absolute;
  left: 45vw;
  top: 45vh;
}

.infoIcon {
  position: relative;
  top: 7px;
  left: 7px;
}

.singleBooking {
  margin: auto;
  margin-top: 15px;
  width: 50vw;
  position: relative;
  left: $view-offset;
  background-color: $base-color;
  border: 1px solid $accent-color;
  border-radius: 15px;
  text-align: center;
}

a:link,
a:visited,
a:active,
a:hover {
  text-decoration: none;
  color: black;
}

.createButton {
  text-decoration: none;
  position: absolute;
  right: 15px;
  top: 15px;
}
