// .datagrid {
//     position: relative;
//     left: -12vw;
// }

a:link,
a:visited,
a:active,
a:hover {
  text-decoration: none;
  color: black;
}

.createButton {
  text-decoration: none;
  position: absolute;
  right: 15px;
  top: 15px;
}
