$nav-width: 10vw;

#navigation {
  margin: 0px;
  padding: 0px;
  width: $nav-width;
  max-width: 20vw;
  
  #left-nav{ 
    margin: 0px;
    padding: 0px;
    display: flex;
    height: 97vh;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  
  .to-bottom {
    position: absolute;
    margin: 0px;
    padding: 0px;
    height: 2vh;
    width: $nav-width;
    bottom: 0vh;
  }
}