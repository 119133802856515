.login, .register{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    height: 100%;
    background-color: #ececec;
    .title{
        color: black;
        cursor: pointer;
    }
    .slogan{
        // color: yellow;
    }
    .form{
        display: flex;
        align-items: center;
        flex-direction: column;
        background-color: whitesmoke;
        gap: 1rem;
        padding: 2rem 4rem;
        border-radius: 10px;
        box-shadow: 3px 3px 20px 6px rgba($color: #000000, $alpha: .5);
        height: 60vh;
        .error{
            background-color: red;
            color: white;
            padding: .75em;
            border-radius: 3px;
        }
    }
}