$grey: #F8F8F8;
.App{
    display: grid;
    grid-template-columns: 1fr 5fr;
    height: 100vh;
    .content{
        display: flex;
        background-color: $grey;
        padding: 2rem;
    }
}